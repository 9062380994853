/** @jsxImportSource theme-ui */
import { FC, useEffect, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'
import useReRenderOnMountKeyMobile from 'src/utils/useReRenderOnMountKeyMobile'
import { PrismicHomepageDataType, PrismicImageType } from 'root/graphql-types'
import FullViewportHeadline from 'src/components/molecules/FullViewportHeadline'
import {
  singleFullWidthHeadingSx,
  gridMarginArray,
} from 'src/gatsby-plugin-theme-ui/sizes'
import SEO from 'src/components/organisms/SEO'
import { setActiveLink } from 'src/components/organisms/NavBar/NavBarDesktop'
import { isPageLoaded } from 'src/components/layouts/MainLayout'
import OffsetProjects from 'src/components/pages/home/OffsetProjects'
import { EE } from 'src/utils/eventEmitter'
import { xs } from 'src/gatsby-plugin-theme-ui/utils'
// @ts-ignore
import IntroTextDesktop from 'src/svg/intro_text_desktop.svg'
// @ts-ignore
import IntroTextMobile from 'src/svg/intro_text_mobile.svg'
import { useGridColumnsWidth } from 'src/utils/useGridColumnsWidth'
import { isMobile } from 'react-device-detect'

type HomeProps = {
  pageContext: PrismicHomepageDataType & {
    heroVideoDesktop: any
    heroVideoMobile: any
    seo_image_1200x1200: PrismicImageType
    alternate_languages: any
    lang: any
    hero_text: any
  }
  path: string
}

/** in seconds */
const HIDE_HERO_TEXT_DELAY = 2

/** trigger shutter animation 100 ms before hero text animation ends */
const TRIGGER_HERO_TEXT_ANIMATION_DONE = 1900

const Home: FC<HomeProps> = ({ pageContext, path }) => {
  const projects = pageContext?.projects
  setActiveLink(null)
  const heroTextContainerControls = useAnimation()
  const heroTextControls = useAnimation()
  const [showHeroText, setShowHeroText] = useState(true)
  const getColumnsWidth = useGridColumnsWidth()

  useEffect(() => {
    if (isPageLoaded()) {
      setShowHeroText(false)
      return
    }

    const start = async () => {
      setTimeout(
        () => EE.emit('homepage-hero-text-animation-done'),
        TRIGGER_HERO_TEXT_ANIMATION_DONE
      )

      heroTextControls.start(() => ({
        opacity: 1,
        transition: {
          duration: 0.5,
          delay: 0.3,
          ease: 'linear',
        },
      }))
      heroTextControls.start(() => ({
        filter: 'blur(5px)',
        transform: 'scale(1.04)',
        transition: {
          duration: 0.4,
          delay: HIDE_HERO_TEXT_DELAY - 0.2,
          ease: 'linear',
        },
      }))
      await heroTextContainerControls.start(() => ({
        opacity: 0,
        transition: {
          duration: 0.25,
          delay: HIDE_HERO_TEXT_DELAY,
          ease: [0.07, 0, 0, 0.99],
        },
      }))

      setShowHeroText(false)
    }

    if (showHeroText) start()
  }, [heroTextControls, heroTextContainerControls, showHeroText])

  const reRenderOnMountKeyMobile = useReRenderOnMountKeyMobile('homepage')

  return (
    <div key={reRenderOnMountKeyMobile}>
      <SEO
        title={pageContext?.seo_title || ''}
        image={pageContext?.seo_image_1200x1200?.fluid?.src || ''}
        description={pageContext?.seo_description || ''}
        path={path}
        alternate_languages={pageContext.alternate_languages}
        lang={pageContext.lang}
      />
      {showHeroText ? (
        <motion.div
          key={reRenderOnMountKeyMobile}
          sx={{
            position: 'absolute',
            top: 0,
            zIndex: 30000,
            bg: 'black',
            height: '100vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            px: gridMarginArray,
            marginLeft: singleFullWidthHeadingSx,
          }}
          animate={heroTextContainerControls}
        >
          <motion.div
            sx={{
              opacity: isMobile ? 1 : 0,
              filter: 'blur(0px)',
              transform: 'scale(1)',
            }}
            animate={heroTextControls}
          >
            <IntroTextDesktop
              sx={{
                display: xs('none', 'block'),
                width: getColumnsWidth(10),
                height: '100%',
              }}
            />
            <IntroTextMobile
              sx={{
                display: xs('block', 'none'),
                width: '100%',
                height: '100%',
              }}
            />
          </motion.div>
        </motion.div>
      ) : null}
      <FullViewportHeadline headline={pageContext?.intro_text} />
      <OffsetProjects projects={projects} />
    </div>
  )
}

export default Home
